<template>
  <div class="app-container">
    <!-- 查询区域 -->
    <div>
      <el-form :model="queryParams" layout="inline" :inline="true">
        <el-row>
          <el-col :md="6" :sm="24" style="width: 70%">
            <el-button type="primary" @click="selectDataListButton()"
              >搜索</el-button
            >
            <el-button type="primary" @click="primaryRest()">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 按钮区 -->
    <el-row :gutter="20" style="margin: 9px 2px">
      <el-button type="success" icon="el-icon-add" size="min" @click="addButton"
        >新增</el-button
      >
      <el-button
        type="success"
        icon="el-icon-add"
        size="min"
        @click="editButton"
        >编辑</el-button
      >
      <el-button
        type="success"
        icon="el-icon-add"
        size="min"
        @click="delectButton"
        disabled
        >删除</el-button
      >
      <el-button
        type="success"
        icon="el-icon-add"
        size="min"
        @click="selectButton(this.id)"
        disabled
        >查看</el-button
      >
    </el-row>

    <!--     列表区域   -->
    <el-table :data="dataColumnList">
      <el-table-column label="序号" align="center" width="65">
        <template slot-scope="scope">
          <el-radio
            :label="scope.$index"
            v-model="radio"
            @change.native="getCurrentRow(scope.row)"
          ></el-radio>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="selectButton(scope.row)"
            disabled
            >查看</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="selectButton(scope.row)"
            disabled
            >配置售卖</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="类型" align="center" prop="type" />
      <el-table-column label="编码" align="center" prop="code" />
      <el-table-column label="名称" align="center" prop="name" />
      <el-table-column label="规格" align="center" prop="specs" />
      <el-table-column label="定价" align="center" prop="pricing" />
      <el-table-column label="状态" align="center" prop="status" />
    </el-table>
    <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />
    <addEdit ref="addEditFrom" />
  </div>
</template>


<script>
import addEdit from "./modules/addEditcombo";
import { listcomboApi } from "@/api/system/combo/combo.js";

export default {
  components: {
    addEdit,
  },
  data() {
    return {
      // 选中数组
      radio: null,
      ids: null,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      optFrom: {},
      dataColumnList: [],
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectDataListButton();
    },
    "queryParams.pageSize"(val) {
      this.selectDataListButton();
    },
  },
  created() {
    this.selectDataListButton();
  },
  methods: {
    selectDataListButton() {
      listcomboApi().then((res) => {
        this.dataColumnList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.ids = row.id;
      this.optFrom = row;
    },
    addButton() {
      this.$refs.addEditFrom.show(null, 1);
    },
    editButton() {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
      this.$refs.addEditFrom.show(this.ids, 2);
    },
    delectButton() {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
    },
    selectButton() {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
    },
    primaryRest() {
      this.$refs["queryParams"].resetFields();
      this.selectDataListButton();
    },
  },
};
</script>



